<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="EXP_CATS"
      tooltip-title="EXP_CATS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(categoriesList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="(head, index) in tableHeaders" :key="index">
            {{ head }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(category, index) in categoriesList" :key="index">
          <TD>
            {{ category.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ category.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            <TableAction
              :idx="index"
              :current-user="category"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(categortiesCount, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />
    <ExpenseCategoriesModal
      v-if="isExpenseCategoriesModal"
      :modal="isExpenseCategoriesModal"
      :edit="currentSeletecCategory"
      @toggle="toggleExpenseCategoriesModal"
    />
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_CATEGORY"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentSeletecCategory.title"
      remaining-message="Expense Category ?"
      @cancel="toggleConfirmationModal"
      @confirm="deleteCategory"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import isEmpty from 'lodash/isEmpty'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { objectDeepCopy } from '@/src/utils/generalUtil'
import scrollMixin from '@src/mixins/scroll-mixin'
import ExpenseCategoriesModal from '@views/expense-manager/categories/AddExpenseCategoriesModal.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import NoRecordFound from '@src/components/BaseComponent/NoRecordFound.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TitleWrapper,
    TableAction,
    Loader,
    ExpenseCategoriesModal,
    UIConfirmationModal,
    Pagination,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, scrollMixin],
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      expense: 'expense',
      categoriesList: [],
      dashboard: 'dashboard',
      tableHeaders: ['Title', 'Description', 'Actions'],
      currentSeletecCategory: null,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      isExpenseCategoriesModal: false,
      showPagination: false,
      filteredRecordLimit: 10,
      confirmation: false,
      categortiesCount: 0,
    }
  },
  page: {
    title: 'Expense | Category',
    meta: [
      {
        name: 'description',
        content: 'Manage categories of expenses ',
      },
    ],
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.layout.isLoading,
      currentCampusScope: (state) => state?.layout?.currentCampusScope,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.setRightbarData()
        this.filterRecord()
      },
    },
    showModal: {
      handler(value) {
        if (value) this.toggleExpenseCategoriesModal()
      },
    },
  },
  mounted() {
    this.setRightbarData()
    this.filterRecords()
  },
  methods: {
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentSeletecCategory = null
    },
    toggleExpenseCategoriesModal(payload) {
      this.isExpenseCategoriesModal = !this.isExpenseCategoriesModal
      if (!this.isExpenseCategoriesModal) {
        this.currentSeletecCategory = null
        this.$store.dispatch('layout/setShowModal', false)
      }
      if (payload) this.filterRecords()
    },
    processPaginationPayload(range) {
      return {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
    },
    filterRecords(range) {
      this.$store.commit('layout/IS_LOADING', true)

      let payload = this.processPaginationPayload(range)

      this.getExpenseCategories(payload).then((res) => {
        this.categoriesList = res.records
        this.categortiesCount = res.meta.total_records
        this.showPagination = this.categortiesCount > GENERAL_CONSTANTS.RECORD_LIMIT
        this.$store.commit('layout/IS_LOADING', false)
        this.setRightbarData(this.categortiesCount)
      })
    },

    typeAction(action, currentCategory, idx) {
      this.currentSeletecCategory = currentCategory
      switch (action) {
        case 'Edit':
          this.toggleExpenseCategoriesModal()
          break
        case 'Delete':
          this.toggleConfirmationModal()
          break
      }
    },

    deleteCategory() {
      this.deleteExpenseCategories(this.currentSeletecCategory.id).then(() => {
        this.filterRecords()
        this.$store.commit('toast/NEW', {
          message: `${this.currentSeletecCategory.title} Deleted Successfully`,
          type: 'success',
        })
        this.currentSeletecCategory = null
        this.confirmation = false
      })
    },

    setRightbarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'RB_ECa',
          buttons: [
            {
              title: 'RB_AEC',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_NO_CATEGORIES',
                value: count,
                tooltipDescription: !this.currentCampusScope
                  ? 'Total types of expense categories for an institute.'
                  : 'Total types of expense categories for an  campus.',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('expense', ['getExpenseCategories', 'deleteExpenseCategories']),
  },
}
</script>
