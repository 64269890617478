<template>
  <div>
    <ValidationObserver @submit="addCategory">
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="modal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span>
            <span v-if="!edit">Add Expense Category</span>
            <span v-else>Edit Expense Category</span>
          </span>
        </template>
        <template v-slot>
          <InputFieldWrapper>
            <InputBox
              v-model="expenseCategory.title"
              type="text"
              name="TITLE"
              title="TITLE"
              label="Expense Categories"
              placeholder="TITLE"
              :class="$style.widthItems"
              class="flex-1 w-full"
              rules="required"
            />
            <InputBox
              v-model="expenseCategory.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
              :class="$style.widthItems"
              class="flex-1 w-full"
            />
          </InputFieldWrapper>
        </template>
        <template v-slot:footer>
          <div class="flex gap-3 rtl:gap-x-4">
            <UIButton @click="handleClick('close')">Cancel</UIButton>
            <UIButton button="primary">
              <span v-if="isLoading">
                <Loader />
              </span>
              <span v-else>
                <span v-if="edit">Update</span>
                <span v-else>Save</span>
              </span>
            </UIButton>
          </div>
        </template>
      </UiModalContainer>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    Loader,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      expenseCategory: {
        title: '',
        description: '',
      },
      isLoading: false,
    }
  },
  mounted() {
    if (this.edit) this.setEditCategoryData()
  },
  methods: {
    addCategory() {
      this.isLoading = true
      const data = {
        category: this.expenseCategory,
      }
      if (this.edit) data.id = this.edit?.id
      this.addAndupdateExpenseCategories(data)
        .then((response) => {
          let message = `${response.title} ${this.edit?.id ? 'updated' : 'added'} Successfully `
          this.$store.commit('toast/NEW', { message: message, type: 'success' })
          this.$emit('toggle', 'fetch')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setEditCategoryData() {
      this.expenseCategory.title = this.edit?.title || ''
      this.expenseCategory.description = this.edit?.description || ''
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    ...mapActions('expense', ['addAndupdateExpenseCategories']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
